import React from "react"
import Layout from "../components/Layout"
import Login from "../components/Login"

const Index = () => (
  <Layout>
    <Login />
  </Layout>
)

export default Index
