import React from "react"
import PropTypes from "prop-types"

const View = ({ title, children }) => (
  <>
    {children}
  </>
)

View.propTypes = {
  title: PropTypes.string.isRequired,
}

export default View
